import Axios, { AxiosPromise } from "axios";

/**
 * Returns a document
 *
 * @export
 * @param {*} action
 * @returns {AxiosPromise}
 */
export function getDocument(filename: string): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/documents/" + filename;
  const config = {
    // timeout 30 s
    timeout: 30000,
  };
  return Axios.get(request, config);
}

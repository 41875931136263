import { useEffect, useState } from "react";
import { getDocument } from "../api/documents";
import styled from "styled-components";
import { Header } from "./Header";

const DocumentPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const DocumentEmbed = styled.embed`
  flex: 1;
  flex-grow: 1;
`;

export const DocumentViewer = (props: any) => {
  const { filename } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<any>();
  const [error, setError] = useState<any>();

  useEffect(() => {
    const fetchDocument = async (_filename: string) => {
      try {
        const _document = await getDocument(_filename);
        return setDocument(_document.data);
      } catch (e: any) {
        console.error(e);
        setError(e.message);
        return;
      }
    };

    setLoading(false);
    fetchDocument(filename);
  }, [filename]);

  if (loading) {
    return <h2>"Loading..."</h2>;
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (document) {
    return (
      <DocumentPage>
        <Header />
        <DocumentEmbed src={document} type="application/pdf" />
      </DocumentPage>
    );
  }

  return null;
};
